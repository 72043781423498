import React from "react"
// import { StaticImage } from "gatsby-plugin-image";

export default function Hero() {
  return (
    <div className="h-full">
      <div className="h-full relative flex flex-col justify-end items-end">
        <div className="vimeo-wrapper bg-black">
          <div className="video-wrapper h-full w-full overflow-hidden cover object-cover">
            <video
              className="object-cover max-h-auto inset-0 w-full h-full"
              autoPlay
              playsInline
              muted
              loop
            >
              <source
                src="https://olleburl.in/korplot/stabteknik/wp-content/uploads/sites/3/2023/09/stabteknik2.mp4"
                type="video/mp4"
              />
            </video>
          </div>
        </div>
        <div className="relative px-4  pb-4 md:px-16 md:pb-16">
          <div className="bg-secondary bg-opacity-90 max-w-lg p-4 md:p-6 rounded space-y-2 md:space-y-4">
            <h1 className=" font-sans text-2xl md:text-3xl font-bold tracking-tight leading-normal text-brand">
              Välkommen till <br className="md:hidden block" />
              Stab Teknik
            </h1>
            <div>
              <p className="text-base pb-4">
                Vi är ett företag med fokus på rivning, skrotning,
                ombyggnadsprojekt, demontering samt återvinning av järn och
                metall.
              </p>
            </div>

            <div className="flex items-center">
              <a
                href="/kontakt"
                className="inline-flex items-center justify-center h-12 px-6 mr-6 font-medium tracking-wider text-secondary transition duration-200 rounded shadow-sm bg-brand hover:bg-opacity-80 focus:shadow-outline focus:outline-none"
              >
                Kontakta oss
              </a>
              <a
                href="/#om"
                aria-label=""
                className="inline-flex items-center rounded font-medium h-12 px-6 bg-gray-300 text-gray-900 transition-colors duration-200 hover:opacity-70"
              >
                Läs mer
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
