import PropTypes from "prop-types"
import React from "react"
import Footer from "./footer"

import Header from "./header"
import Hero from "./index/Hero"

function Layout({ children, isHome }) {
  return (
    <div className="flex flex-col text-gray-900 md:min-h-screen">
      {isHome ? (
        <div className="flex flex-col h-[80vh] md:h-screen">
          <div className="bg-white">
            <Header />
          </div>

          <section className="flex-1 ">
            <Hero />
          </section>
        </div>
      ) : (
        <div className="flex flex-col">
          <div className="bg-white">
            <Header />
          </div>
        </div>
      )}
      <main className="flex-1 w-full bg-secondary">{children}</main>

      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
