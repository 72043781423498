import { Link } from "gatsby"
import React from "react"

export default function Footer() {
  return (
    <footer className="bg-brand text-gray-100">
      <nav className="flex justify-center max-w-4xl p-4 mx-auto text-sm md:p-8">
        <p>
          Stab Teknik AB, Jörgen Kocksgatan 75, 211 20 Malmö,{" "}
          <a
            href="tel:+4640394900"
            className="text-white underline font-normal"
          >
            +46 (0)40-39 49 00.
          </a>{" "}
          <Link
            to="/allmanna-villkor"
            className="text-white underline font-normal"
          >
            Allmänna Villkor
          </Link>
          .
        </p>
      </nav>
    </footer>
  )
}
